/**
 * This file includes polyfills needed by Angular and is loaded before the app.
 * You can add your own extra polyfills to this file.
 *
 * This file is divided into 2 sections:
 *   1. Browser polyfills. These are applied before loading ZoneJS and are sorted by browsers.
 *   2. Application imports. Files imported after ZoneJS that should be loaded before your main
 *      file.
 *
 * The current setup is for so-called "evergreen" browsers; the last versions of browsers that
 * automatically update themselves. This includes Safari >= 10, Chrome >= 55 (including Opera),
 * Edge >= 13 on the desktop, and iOS 10 and Chrome on mobile.
 *
 * Learn more in https://angular.io/docs/ts/latest/guide/browser-support.html
 */

/***************************************************************************************************
 * BROWSER POLYFILLS
 */

/** IE9, IE10 and IE11 requires all of the following polyfills. **/
import 'core-js/es6/symbol';
import 'core-js/es6/object';
import 'core-js/es6/function';
import 'core-js/es6/parse-int';
import 'core-js/es6/parse-float';
import 'core-js/es6/number';
import 'core-js/es6/math';
import 'core-js/es6/string';
import 'core-js/es6/date';
import 'core-js/es6/array';
import 'core-js/es6/regexp';
import 'core-js/es6/map';
import 'core-js/es6/weak-map';
import 'core-js/es6/set';

/** IE10 and IE11 requires the following for NgClass support on SVG elements */
// import 'classlist.js';  // Run `npm install --save classlist.js`.

/** IE10 and IE11 requires the following for the Reflect API */
import 'core-js/es6/reflect';
import 'core-js/es7/array';
import 'core-js/es7/object';

/** Evergreen browsers require these. **/
import 'core-js/es7/reflect';

/**
 * Required to support Web Animations `@angular/animation`.
 * Needed for: All but Chrome, Firefox and Opera. http://caniuse.com/#feat=web-animation
 **/
import 'web-animations-js';  // Run `npm install --save web-animations-js`.

if (!Array.prototype.indexOf) {
  Array.prototype.indexOf = function(searchValue, index) {
    // In non-strict-mode, if the `this` variable is null
    // or undefined, then it is set the the window object.
    // Else, `this` is automaticly converted to an object.
    let len = this.length >>> 0; // convert ot number or 0

    index |= 0;              // rounds and NaN-checks
    if (index < 0) {             // check if negative start
      index = Math.max(len - index, 0);
    } else if (index >= len) { // else, check if too big
      return -1;
    }

    if (searchValue === undefined) {
    // Because searchValue is undefined, keys that
    // don't exist will have the same value as the
    // searchValue, and thus do need to be checked.
      do {
        if (index in this && this[index] === undefined) {
          return index;
        }
      } while (++index !== len);
    } else {
    // Because searchValue is not undefined, there's no
    // need to check if the current key is in the array
    // because if the key isn't in the array, then it's
    // undefined which is not equal to the searchValue.
      do {
        if (this[index] === searchValue) {
          return index;
        }
      } while (++index !== len);
    }
    // if nothing was found, then simply return -1
    return -1;
  };
}



/***************************************************************************************************
 * Zone JS is required by Angular itself.
 */
import 'zone.js/dist/zone';  // Included with Angular CLI.



/***************************************************************************************************
 * APPLICATION IMPORTS
 */

/**
 * Date, currency, decimal and percent pipes.
 * Needed for: All but Chrome, Firefox, Edge, IE11 and Safari 10
 */
// import 'intl';  // Run `npm install --save intl`.
/**
 * Need to import at least one locale-data with intl.
 */
// import 'intl/locale-data/jsonp/en';


Date.prototype.toISOString = function() {
  var tzo = -this.getTimezoneOffset(),
    dif = tzo >= 0 ? '+' : '-',
    pad = function(num) {
      var norm = Math.floor(Math.abs(num));
      return (norm < 10 ? '0' : '') + norm;
    };
  return this.getFullYear() +
    '-' + pad(this.getMonth() + 1) +
    '-' + pad(this.getDate()) +
    'T' + pad(this.getHours()) +
    ':' + pad(this.getMinutes()) +
    ':' + pad(this.getSeconds()) +
    dif + pad(tzo / 60) +
    ':' + pad(tzo % 60);
};
